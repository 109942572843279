// import React from "react";
// import "./Homepage.css";
// import image1 from "./../../assets/images/1.png";
// import image2 from "./../../assets/images/2.png";
// import image3 from "./../../assets/images/3.png";
// import image4 from "./../../assets/images/4.png";
// import image5 from "./../../assets/images/5.png";

// import image6 from "./../../assets/images/6.png";
// import image7 from "./../../assets/images/7.png";
// import image8 from "./../../assets/images/8.png";
// import image9 from "./../../assets/images/9.png";
// import image9mobile from "./../../assets/images/9mobile.png";

// import image10 from "./../../assets/images/10.png";
// import image11 from "./../../assets/images/11.png";
// import image12 from "./../../assets/images/12.png";

// const GridPage = () => (
//   <div className="grid-container">
//     {/* First row */}
//     <a
//       className="grid-item item-1"
//       target="_blank"
//       rel="noreferrer"
//       href="https://t.me/bitgramtoken"
//     >
//       <div>
//         {" "}
//         <img src={image1} alt="Description" />
//       </div>
//     </a>
//     <div className="grid-item item-2">
//       {" "}
//       <img src={image2} alt="Description" />
//     </div>
//     <div className="grid-item item-3">
//       {" "}
//       <img src={image3} alt="Description" />
//     </div>
//     <div className="grid-item item-4">
//       {" "}
//       <img src={image4} alt="Description" />
//     </div>
//     <div className="grid-item item-5">
//       {" "}
//       <img src={image5} alt="Description" />
//     </div>
//     {/* Second row */}
//     <a
//       className="grid-item item-6"
//       rel="noreferrer"
//       target="_blank"
//       href="https://twitter.com"
//     >
//       <div>
//         {" "}
//         <img src={image6} alt="Description" />
//       </div>
//     </a>
//     <a
//       className="grid-item item-7"
//       target="_blank"
//       rel="noreferrer"
//       href="https://t.me/BitGram_BitDrop_bot"
//     >
//       <div>
//         {" "}
//         <img src={image7} alt="Description" />
//       </div>
//     </a>
//     <div className="grid-item item-8">
//       {" "}
//       <img src={image8} alt="Description" />
//     </div>
//     <div className="grid-item item-9">
//       {" "}
//       <img src={image9} alt="Description" />
//     </div>
//     <div className="grid-item mobile-item-9">
//       {" "}
//       <img src={image9mobile} alt="Description" />
//     </div>
//     {/* Third row */}
//     <div className="grid-item item-10">
//       {" "}
//       <img src={image10} alt="Description" />
//     </div>
//     <div className="grid-item item-11">
//       {" "}
//       <img src={image11} alt="Description" />
//     </div>
//     <div className="grid-item item-12">
//       {" "}
//       <img src={image12} alt="Description" />
//     </div>
//   </div>
// );

// export default GridPage;
import React, { useState } from "react";
import "./Homepage.css";
import image1 from "./../../assets/images/1.png";
import image2 from "./../../assets/images/2.png";
import image3 from "./../../assets/images/3.png";
import image4 from "./../../assets/images/4.png";
import image5 from "./../../assets/images/5.png";
import image6 from "./../../assets/images/6.png";
import image7 from "./../../assets/images/7.png";
import image8 from "./../../assets/images/8.png";
import image9 from "./../../assets/images/9.png";
import image9mobile from "./../../assets/images/9mobile.png";
import image10 from "./../../assets/images/10.png";
import image11 from "./../../assets/images/11.png";
import image12 from "./../../assets/images/12.png";

const GridPage = () => {
  const [isVideoOpen, setVideoOpen] = useState(false);

  const handleVideoOpen = () => {
    setVideoOpen(true);
  };

  const handleVideoClose = () => {
    setVideoOpen(false);
  };

  return (
    <div className="grid-container">
      {/* First row */}
      <a
        className="grid-item item-1"
        target="_blank"
        rel="noreferrer"
        href="https://t.me/bitgramtoken"
      >
        <div>
          <img src={image1} alt="Description" />
        </div>
      </a>
      <div className="grid-item item-2">
        <img src={image2} alt="Description" />
      </div>
      <div className="grid-item item-3">
        <img src={image3} alt="Description" />
      </div>
      <div className="grid-item item-4">
        <img src={image4} alt="Description" />
      </div>
      <div className="grid-item item-5" onClick={handleVideoOpen}>
        <img src={image5} alt="Description" />
        <div className="overlay">
          <div className="play-icon">▶️</div>
        </div>
      </div>
      {/* Second row */}
      <a
        className="grid-item item-6"
        rel="noreferrer"
        target="_blank"
        href="https://twitter.com"
      >
        <div>
          <img src={image6} alt="Description" />
        </div>
      </a>
      <a
        className="grid-item item-7"
        target="_blank"
        rel="noreferrer"
        href="https://t.me/BitGram_BitDrop_bot"
      >
        <div>
          <img src={image7} alt="Description" />
        </div>
      </a>
      <div className="grid-item item-8">
        <img src={image8} alt="Description" />
      </div>
      <div className="grid-item item-9">
        <img src={image9} alt="Description" />
      </div>
      <div className="grid-item mobile-item-9">
        <img src={image9mobile} alt="Description" />
      </div>
      {/* Third row */}
      <div className="grid-item item-10">
        <img src={image10} alt="Description" />
      </div>
      <div className="grid-item item-11">
        <img src={image11} alt="Description" />
      </div>
      <div className="grid-item item-12">
        <img src={image12} alt="Description" />
      </div>

      {isVideoOpen && (
        <div className="video-popup">
          <div className="video-popup-content">
            <button className="close-button" onClick={handleVideoClose}>
              ✖️
            </button>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/QH4HQX-OOcg"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default GridPage;
