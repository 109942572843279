import React from "react";
import "./App.css";
import HomePage from "./components/homepage/Homepage"; // Ensure that HomePage.js is in the correct path

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;
